<style lang="less" scoped>
.black {
    .nav {
        background-color: #000;
    }
}
</style>
<template>
    <div class="top_bar" :class="$route.meta.black == 'black' ? 'black' : ''">
        <div class="left">
            <div
                class="logo-img"
                :style="{ width: isCollapse ? '60px' : '168px' }"
                @click="to_home"
            >
                <img
                    src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/logo_i.png"
                    class="logo"
                    alt=""
                />
                <transition name="el-zoom-in-center">
                    <img
                        v-show="!isCollapse"
                        src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/logo_txt.png"
                        class="logo_txt"
                        alt=""
                    />
                </transition>
            </div>

            <i
                v-if="!nav"
                :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
                @click="setnav"
            ></i>
        </div>
        <div class="nav" v-if="nav">
            <ul>
                <li
                    v-for="(item, index) in nav"
                    :key="index"
                    @click="toLink(item.path)"
                    v-show="!item.meta.hidden"
                >
                    <div
                        class="nav-btn"
                        :class="$route.path == item.path ? 'active' : ''"
                    >
                        <i class="iconfont" :class="item.meta.icon"></i>
                    </div>
                </li>
            </ul>
        </div>
        <div class="right">
            <div class="user">
                <div class="btns-box">
                    <el-popover
                        placement="bottom"
                        width="100"
                        trigger="hover"
                        v-if="!nav"
                    >
                        <div>暂未开放</div>
                        <div slot="reference" class="icon-btn">
                            <i class="iconfont icon-a-sousuo"></i>
                        </div>
                    </el-popover>
                    <el-popover
                        placement="bottom-end"
                        trigger="hover"
                        @show="showTask"
                    >
                        <div
                            class="task-box"
                            v-loading="loading"
                            element-loading-text="拼命加载中"
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(255, 255, 255, 0.8)"
                        >
                            <TASK_MODEL :tasks="tasks" />
                        </div>
                        <div slot="reference" class="icon-btn">
                            <i class="iconfont icon-a-renwuliebiao"></i>
                        </div>
                    </el-popover>
                    <el-popover placement="bottom" width="100" trigger="hover">
                        <div>暂未开放</div>
                        <div slot="reference" class="icon-btn">
                            <i class="iconfont icon-a-xiaoxitixing"></i>
                        </div>
                    </el-popover>
                </div>
                <div class="btns" v-if="nav">
                    <el-button
                        type="primary"
                        :loading="gitLoading"
                        size="mini"
                        @click="savaSetting"
                    >
                        <span v-if="gitLoading">正在保存..</span>
                        <span v-else>保存配置</span>
                    </el-button>

                    <el-button size="mini" @click="exitPoje()">退出</el-button>
                </div>
                <div class="user-info" v-if="!nav">
                    <el-dropdown>
                        <div class="down-box">
                            <img
                                class="user-logo"
                                src="https://img0.baidu.com/it/u=1250551608,2180019998&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1670778000&t=87cbdefa412ee23efa24a2f73e434484"
                                alt=""
                                srcset=""
                            />
                            <span>{{ $store.state.user.account }}</span>
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <router-link to="/home/user/index">
                                    个人信息
                                </router-link>
                            </el-dropdown-item>

                            <el-dropdown-item>
                                <router-link to="/home/user/password">
                                    修改密码
                                </router-link>
                            </el-dropdown-item>

                            <el-dropdown-item>
                                <router-link to="/home/user/order">
                                    消费记录
                                </router-link>
                            </el-dropdown-item>
                            <el-dropdown-item @click.native="logout"
                                >退出登录</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import {  } from "@/utils/";
import { logout } from "../api/user";
import Bus from "@/utils/eventBus.js";
import TASK_MODEL from "@/components/task/task_model";
import { taskList } from "@/api/engi";
import { timeout } from "q";

export default {
    name: "",
    props: ["nav"],
    components: {
        TASK_MODEL,
    },
    data() {
        return {
            loading: true,
            gitLoading: false,
            isCollapse: false,
            tasks: [],
        };
    },

    mounted() {
        // console.log(this.nav);
        Bus.$on("setLoading", (res) => {
            this.gitLoading = res;
        });
    },

    methods: {
        showTask() {
            this.loading = true;

            taskList().then((res) => {
                this.tasks = res.data.records;
                this.loading = false;
            });
            // console.log("显示");
        },
        exitPoje() {
            this.$router.push("/home/index");
        },
        setnav() {
            this.isCollapse = this.isCollapse ? false : true;
            // this.navType = isType;
            Bus.$emit("navType", this.isCollapse);
        },
        to_home() {
            if (this.$route.path != "/home/index") {
                this.$router.push({ path: "/home/index" });
            }
        },
        toLink(link) {
            this.$router.push(link);
        },
        savaSetting() {
            Bus.$emit("save", true);
        },
        logout() {
            // logout().then(res)
            this.$confirm("确定要退出系统么?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    logout().then((res) => {
                        if (res.code == 200) {
                            this.$store.commit("SET_TOKEN", "");
                            this.$store.commit("SET_USER_INFO", "");
                            this.$router.push({ path: "/login" });
                            localStorage.removeItem("auto_token");
                        }
                    });
                })
                .catch(() => {
                    // this.$message.info("已取消退出");
                });
        },
    },
};
</script>

<style lang="less" scoped>
.top_bar {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    margin: 0 -20px;
    padding: 0 20px;
    &.black {
        background-color: #171a1e;
        .nav-btn {
            color: #fff;
        }
    }
}
.left {
    display: flex;
    align-items: center;
    .logo-img {
        // width: 168px;
        transition: width 0.5s linear;
        display: flex;
        flex-wrap: nowrap;
        .logo {
            width: 26px;
        }
        .logo_txt {
            width: 98px;
            margin-left: 2px;
        }
    }
    i {
        font-size: 20px;
        cursor: pointer;
        margin-left: 10px;
    }
}
.nav {
    width: 210px;
    height: 44px;
    background-color: #e8ecef;
    border-radius: 10px;
    img {
        width: 100%;
    }
    ul {
        height: 100%;
        display: flex;
        justify-content: space-around;
        li {
            height: 100%;
            cursor: pointer;
            .nav-btn {
                i {
                    font-size: 20px;
                }
                &.active {
                    color: #fff;
                    background-color: #349ffd;
                }
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                border-radius: 10px;
                font-size: 28px;
            }
            &.active .nav-btn {
                background-color: #349ffd;
                box-shadow: 0px 3px 6px 0px rgba(56, 125, 255, 0.2);
            }
        }
    }
}
.right {
    .user {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .btns-box {
        display: flex;
    }
    .icon-btn {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        background-color: #f5f6f8;
        color: #345582;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
    }
    .user-info {
        display: flex;
        align-items: center;
    }
}
.user-logo {
    width: 34px;
    border-radius: 50%;
}
.down-box {
    display: flex;
    align-items: center;
    span {
        padding: 0 4px;
    }
    .user-info {
        padding: 0 10px;

        .user-id {
            font-size: 12px;
        }

        .user-name {
            // font-size: 13px;
        }
    }
}
</style>