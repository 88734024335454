
const URL = ''
// const URL = "http://192.168.0.191:56666"
const video = 'https://cncsvideo.oss-cn-shanghai.aliyuncs.com/';
const audio = 'https://cncsaudio.oss-cn-shanghai.aliyuncs.com/';
const img = 'https://cncspicture.oss-cn-shanghai.aliyuncs.com/';
// const URL = "https://api.esdli.cn"

module.exports = {
    URL: URL,
}