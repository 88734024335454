import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'

import '@/assets/css/font.css';

import VueTagTextarea from 'vue-tag-textarea'
import 'vue-tag-textarea/lib/vue-tag-textarea.css'

import "./assets/iconfont/iconfont.css";

Vue.use(VueTagTextarea)

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import moment from 'moment'
Vue.prototype.$moent = moment

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import uploader from 'vue-simple-uploader'
Vue.use(uploader);

import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)
// import * as VueJsonp from 'vue-jsonp'
// import VueJsonp from 'vue-jsonp'
// console.log(jsonp);
// Vue.use(VueJsonp)
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)
import 'default-passive-events'

Vue.prototype.$axios = axios

Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.$goBack = () => {
    router.back();
}

router.beforeEach((to, form, next) => {
    NProgress.start();
    let token = store.state.token;
    console.log(to.path);
    if (to.path == '/login' && token) {
        next({
            path: '/home/index'
        });
    }
    let toGroup = to.meta.navGruop
    let formGroup = form.meta.navGruop
    // console.log(toGroup);
    // console.log(formGroup);
    if (to.meta.requireAuth) {
        // console.log('需要验证');
        // // 需要验证token
        if (token == '' || token == undefined) {
            // 未登录，跳转
            //   console.log('未登录');
            next({
                path: '/404'
            });
        } else {

            next();
        }
    } else {
        next();
    }
    next();
});

router.afterEach(() => {
    NProgress.done()
})


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
