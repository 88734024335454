<template>
  <div>
    <div v-for="(item, index) in nav" :key="index">
      <el-menu-item :index="index">
        <i class="el-icon-menu"></i>
        <span slot="title"
          >{{ item.meta.title }} {{ item.children.length }}</span
        >
      </el-menu-item>
      <!-- <template slot="title">
        <i class="el-icon-location"></i>
        <span slot="title">{{ item.meta.title }}</span>
      </template> -->
      <!-- <el-menu-item-group v-else>
        <span slot="title">{{ item.title }}</span>
        <el-menu-item index="1-1">选项1</el-menu-item>
        <el-menu-item index="1-2">选项2</el-menu-item>
      </el-menu-item-group> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Vue20NavItem",

  data() {
    return {
      nav: this.$router.options.routes[1].children,
    };
  },
  created() {
    console.log(this.nav[1].children.length);
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
</style>