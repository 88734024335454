<template>
    <div id="app">
        <div class="web-view">
            <transition name="list-fade">
                <router-view v-if="routerAlive" v-wechat-title="title" />
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            title: "火键智能-AI剪辑、抖音、快手、短视频矩阵、本地生活SaaS平台",
            routerAlive: true,
        };
    },
    methods: {
        reload() {
            this.routerAlive = false;
            this.$nextTick(function () {
                this.routerAlive = true;
            });
        },
    },
    created() {},
    mounted() {},
    beforeCreate() {},
};
</script>
<style>
</style>
<style lang="less">
dl,
dd,
dt,
ul,
li {
    margin: 0;
    padding: 0;
}
* {
    padding: 0;
    margin: 0;
}
a {
    text-decoration: none;
    color: #3c3b3b;
}
body {
    background: #f7f7f7;
    font-size: 14px;
    color: #334681;
    // font-family: "source_regular,Microsoft YaHei, Arial, sans-serif";
    font-family: "source_regular";
    // font-family: "PingFang SC";
}

ul,
li {
    list-style: none;
}
::-webkit-scrollbar {
    width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
    width: 4px;
    border-radius: 4px;
    background-color: #f3f3f3;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 4px;
    background: #8597b2;
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #8597b2;
}
::-webkit-scrollbar {
    height: 4px;
    width: 4px;

    //   background-color: #f5f5f5;
}
.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ellipsis-2 {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.pd10 {
    padding: 10px;
}
#app {
    background: #f6f9ff;
}
.web-view {
    // max-width: 2000px;
    margin: 0 auto;
    height: 100vh;
    // overflow: hidden;
    position: relative;
}
.dropdown-menu li {
    font-size: 12px;
    line-height: 30px;
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
