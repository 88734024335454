import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import store from '../store/index'

import mainRoute from "./routes";
import makeRoute from "./make";

Vue.use(VueRouter)

const routes = [
    {
        path: '/404',
        component: () => import('@/views/404'),
        meta: { requireAuth: false, navGruop: 'error' },
    },
    {
        path: '/clip',
        name: 'clip',
        component: resolve => require(['../views/make/clip.vue'], resolve),
        meta: { requireAuth: true, title: '素材调试' },
    },
    {
        path: '/test',
        name: 'test',
        component: resolve => require(['../views/test.vue'], resolve),
        meta: { requireAuth: false },
    },
    {
        path: '/',
        name: 'index',
        component: resolve => require(['../views/index.vue'], resolve),
        meta: { requireAuth: false, navGruop: 'Login' },
        redirect: { path: '/login' }
    },
    {
        path: '/',
        name: 'login',
        component: resolve => require(['../views/login1.vue'], resolve),
        meta: { requireAuth: false, navGruop: 'Login' },
    },
    {
        path: '/price',
        name: 'price',
        component: resolve => require(['../views/price.vue'], resolve),
        meta: { requireAuth: false, navGruop: 'price' },
    },

    {
        path: '/login1',
        name: 'login1',
        component: resolve => require(['../views/login1.vue'], resolve),
        meta: { requireAuth: false, navGruop: 'Login' },
    },
    {
        path: '/login',
        name: 'login',
        component: resolve => require(['../views/login1.vue'], resolve),
        meta: { requireAuth: false, navGruop: 'Login' }
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        redirect: { path: '/home/index' },
        children: mainRoute
    },
    // {
    //     path: '/fission/list',
    //     name: 'fissionList',
    //     // component: () => import('@/views/make/fiss_list'),
    //     component: resolve => require(['../views/make/fiss_list'], resolve),
    //     meta: { requireAuth: true, navGruop: 'make' },
    // },
    {
        path: '/make',
        name: '',
        component: resolve => require(['../views/make/index.vue'], resolve),
        redirect: { path: '/make/editing' },
        meta: { requireAuth: true, navGruop: 'make' },
        children: makeRoute
    },
    {
        path: '/success',
        name: 'success',
        component: resolve => require(['../views/success.vue'], resolve),
    },
    {
        path: '/h5/publish_list',
        name: 'publish_list',
        component: resolve => require(['../views/h5/publish_list.vue'], resolve),
    },
    {
        path: '/h5/publish_qr',
        name: 'publish_qr',
        component: resolve => require(['../views/h5/publish_qr.vue'], resolve),
    },
    {
        path: '/h5/getpublish',
        name: 'getpublish',
        component: resolve => require(['../views/h5/getpublish.vue'], resolve),
    }, {
        path: '*',
        redirect: '/404',
        hidden: true
    }
]

const router = new VueRouter({
    mode: 'history',
    // mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
