<style lang="less" scoped>
.task-content {
    width: 348px;
    height: 205px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    .task-body {
        padding: 10px 0;
        overflow: auto;
        flex: 1;
        height: 0;
    }
    .tabs-box,
    .task-row,
    .rate-group {
        display: flex;
    }
    .tabs-box > li {
        width: 90px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        border-radius: 20px;
        margin-right: 16px;
        font-size: 12px;
        background-color: #f5f6f8;
        cursor: pointer;
        &.active {
            background-color: #279aff;
            color: #fff;
        }
    }
    .task-row {
        align-items: center;
        padding: 4px 0;
    }
    .rate-group {
        flex-direction: column;
    }

    .rate-box {
        flex: 1;
        padding-left: 10px;
    }
    .success_txt {
        color: #64d8b3;
    }
    .error_txt {
        color: #ff8f93;
    }
    .wait_txt {
        color: #e6a23c;
    }
    .info_txt {
        color: #909399;
    }
    .btn-box {
        width: 80px;
        text-align: center;
    }
}
</style>
<template>
    <div class="task-content">
        <div class="task-hd">
            <ul class="tabs-box">
                <li class="active">任务列表</li>
                <li>推送到创意库</li>
                <li>下载到本地</li>
            </ul>
        </div>
        <div class="task-body">
            <div class="task-row" v-for="(item, index) in tasks" :key="index">
                <div class="icon">
                    <i class="el-icon-picture-outline"></i>
                </div>
                <div class="rate-box">
                    <ul class="rate-group">
                        <li>{{ item.taskName }}</li>
                        <li>
                            <PROGRESS
                                :style="{ height: '8px' }"
                                :isProgress="item.taskStatus == 'Progress'"
                                :status="item.taskStatus"
                            />
                        </li>
                    </ul>
                </div>
                <div class="btn-box">
                    <span
                        v-if="item.taskStatus == 'Success'"
                        class="success_txt"
                        >成功</span
                    >
                    <span
                        v-else-if="item.taskStatus == 'Error'"
                        class="error_txt"
                        >失败</span
                    >
                    <span
                        v-else-if="item.taskStatus == 'Progress'"
                        class="wait_txt"
                        >合成中</span
                    >
                    <span
                        v-else-if="item.taskStatus == 'Ready'"
                        class="info_txt"
                        >就绪</span
                    >
                    <span
                        v-else-if="item.taskStatus == 'Timeout'"
                        class="error_txt"
                        >失败</span
                    >
                    <span v-else class="error_txt">网络错误</span>
                    <!-- <el-button size="mini" round>取消</el-button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { taskList } from "@/api/engi";
import PROGRESS from "@/components/progress";
export default {
    name: "TaskModel",
    components: {
        PROGRESS,
    },
    props: ["tasks"],
    data() {
        return {
            loading: true,
        };
    },

    mounted() {
        // taskList().then((res) => {
        //     this.tasks = res.data.records;
        // });
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
</style>