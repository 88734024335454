import request from '@/utils/request'
import qs from "qs";

export function Login(data) {
    return request({
        url: '/api/oauth/Login',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify(data),
    })
}

// 获取当前用户信息
export function getInfo() {
    return request({
        url: '/api/oauth/CurrentUser',
        method: 'get',
    })
}
export async function getUSer() {
    return request({
        url: '/api/app/User',
        method: 'get'
    })
}
// 退出登录
export function logout() {
    return request({
        url: '/api/oauth/Logout',
        method: 'get'
    })
}
// 重置密码
export function resetPass(data) {
    return request({
        url: '/api/permission/Users/Current/Actions/ModifyPassword',
        method: 'post',
        data,
    })
}
// 消费记录
export function getConsumption(params) {
    console.log(params);
    return request({
        url: '/api/app/PayConsumptionRecord',
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        params,
    })
}
// 充值记录
export function getRecharge(params) {
    return request({
        url: '/api/app/RechargeManagement',
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        params,
    })
}
// 获取token列表
export function getTokenList(params) {
    return request({
        url: '/api/app/AuthorizationToken',
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        params,
    })
}
// 获取token列表
export function createToken(data) {
    return request({
        url: '/api/app/AuthorizationToken',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data,
    })
}
// 充值
export function platformTransfer(data) {
    return request({
        url: '/api/app/RechargeManagement/platformTransfer',
        method: 'post',
        data,
    })
}