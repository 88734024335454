export default [
    //     {
    //     // 首页
    //     path: '/home/index',
    //     name: 'index',
    //     component: resolve => require(['../views/admin/index.vue'], resolve),
    //     // component: resolve => require(['../views/v3/index.vue'], resolve),
    //     children: [],
    //     meta: {
    //         title: "首页",
    //         requireAuth: true,
    //         navGruop: 'index',
    //         img: require('@/assets/images/nav/index.png'),
    //         img_select: require('@/assets/images/nav/index_2.png'),
    //     }
    // },
    {
        // 首页
        path: '/home/index',
        name: 'index',
        component: resolve => require(['../views/v3/index.vue'], resolve),
        // component: resolve => require(['../views/v3/index.vue'], resolve),
        children: [],
        meta: {
            title: "首页",
            requireAuth: true,
            navGruop: 'index2',
            img: require('@/assets/images/nav/index.png'),
            img_select: require('@/assets/images/nav/index_2.png'),
        }
    },
    {
        // 控制台
        path: '/home/work',
        name: '/work',
        component: resolve => require(['../views/work/index.vue'], resolve),
        meta: {
            title: '控制台', requireAuth: true, navGruop: 'word', children: true,
            img: require('@/assets/images/nav/kong.png'),
            img_select: require('@/assets/images/nav/kong_2.png'),
        },
        children: [
            // {
            // path: '/home/work/index',
            // name: 'workIndex',
            // component: resolve => require(['../views/work/new_video.vue'], resolve),
            // meta: { title: '首页', requireAuth: true, navGruop: 'word' },
            // },
            {
                name: 'Material',
                path: '/home/work/Material/Material',
                redirect: '/home/work/library/Material',
                meta: { title: '素材库', requireAuth: true, navGruop: 'word' },
            }, {
                name: 'mev',
                redirect: '/home/work/library/mev',
                path: '/home/work/mev',
                meta: { title: '创意库', requireAuth: true, navGruop: 'word' },
            }, {
                name: 'editing',
                redirect: '/make/editing',
                path: '/home/work',
                meta: { title: '极速裂变', requireAuth: true, navGruop: 'word' },
            }, {
                path: '/home/work/library/:Material',
                name: 'workLibrary',
                component: resolve => require(['../views/work/ku.vue'], resolve),
                meta: { title: '库', requireAuth: true, navGruop: 'word', hidden: true },
            }, {
                path: '/home/work/up',
                name: 'workUp',
                component: resolve => require(['../views/work/up.vue'], resolve),
                meta: { title: '发布视频', requireAuth: true, navGruop: 'word' },
            }, {
                path: '/home/work/remove_logo',
                name: 'workRemove_logo',
                component: resolve => require(['../views/work/remove_logo.vue'], resolve),
                meta: { title: '一键去水印', requireAuth: true, navGruop: 'word' },
            }, {
                path: '/home/work/turn',
                name: 'workTurn',
                component: resolve => require(['../views/work/turn.vue'], resolve),
                meta: { title: '文本转语音', requireAuth: true, navGruop: 'word' },
            }, {
                path: '/home/work/project_list',
                name: 'workTurn',
                component: resolve => require(['../views/make/project_list.vue'], resolve),
                meta: { title: '裂变列表', requireAuth: true, navGruop: 'word', hidden: true },
            }]
    }, {
        // 脚本
        path: '/home/script',
        name: 'script',
        component: resolve => require(['../views/script/index.vue'], resolve),
        meta: {
            title: "脚本库", requireAuth: true, navGruop: 'script', children: true,
            img: require('@/assets/images/nav/script.png'),
            img_select: require('@/assets/images/nav/script_2.png'),
        },
        redirect: { path: '/home/script/scriptlibrary' },
        children: [{
            name: 'scriptLibrary',
            path: '/home/script/scriptlibrary',
            component: resolve => require(['../views/script/scriptLibrary.vue'], resolve),
            meta: {
                title: "脚本管理", requireAuth: true, navGruop: 'script'
            },
        }, {
            name: 'myscript',
            path: '/home/script/myscript',
            component: resolve => require(['../views/script/myscript.vue'], resolve),
            meta: {
                title: "我的脚本", requireAuth: true, navGruop: 'script'

            },
        }, {
            name: 'createScript',
            path: '/home/script/createscript',
            component: resolve => require(['../views/script/createScript.vue'], resolve),
            meta: { title: "创建脚本", requireAuth: true, navGruop: 'script', hidden: true },
        }]
    }, {
        // 矩阵管理
        path: '/home/matrix',
        name: 'matrix',
        component: resolve => require(['../views/matrix/index.vue'], resolve),
        meta: {
            title: "矩阵管理", requireAuth: true, navGruop: 'matrix', children: true,
            img: require('@/assets/images/nav/ju.png'),
            img_select: require('@/assets/images/nav/ju_2.png'),
        },
        redirect: { path: '/home/matrix/index' },
        children: [{
            path: '/home/matrix/index',
            name: 'matrix_list',
            component: resolve => require(['../views/admin/index.vue'], resolve),
            meta: { title: "数据中心", requireAuth: true, navGruop: 'matrix' },
        }, {
            path: '/home/matrix/list',
            name: 'matrix_list',
            component: resolve => require(['../views/matrix/list.vue'], resolve),
            meta: { title: "账号列表", requireAuth: true, navGruop: 'matrix' },
        }, {
            path: '/home/matrix/gruop',
            name: 'matrix_gruop',
            component: resolve => require(['../views/matrix/gruop.vue'], resolve),
            meta: { title: "账号分组", requireAuth: true, navGruop: 'matrix' },
        }, {
            path: '/home/matrix/task',
            name: 'matrix_task',
            component: resolve => require(['../views/matrix/task.vue'], resolve),
            meta: { title: "任务列表", requireAuth: true, navGruop: 'matrix' },
        }, {
            path: '/home/matrix/sms',
            name: 'matrix_sms',
            component: resolve => require(['../views/matrix/sms_list.vue'], resolve),
            meta: { title: "私信管理", requireAuth: true, navGruop: 'matrix' },
        }, {
            path: '/home/matrix/letter',
            name: 'matrix_sms',
            component: resolve => require(['../views/matrix/letter.vue'], resolve),
            meta: { title: "评论管理", requireAuth: true, navGruop: 'matrix' },
        }]
    }, {
        // 引流工具
        path: '/home/tool',
        name: 'tool',
        component: resolve => require(['../views/tool/index.vue'], resolve),
        children: [],
        meta: {
            title: "引流工具", requireAuth: true, navGruop: 'tool',
            img: require('@/assets/images/nav/key.png'),
            img_select: require('@/assets/images/nav/yin_2.png'),
        }
    }, {
        // 营销工具
        path: '/home/marketing',
        name: 'marketing',
        component: resolve => require(['../views/marketing/marketing.vue'], resolve),

        children: [
            {
                path: '/home/marketing/index',
                name: 'marketingIndex',
                component: resolve => require(['../views/marketing/index.vue'], resolve),
                meta: { title: "营销看板", requireAuth: true, navGruop: 'marketing' },
            },
            {
                path: '/home/marketing/comment',
                name: 'marketingComment',
                component: resolve => require(['../views/marketing/comment.vue'], resolve),
                meta: { title: "评论回复", requireAuth: true, navGruop: 'marketing' },
            },
            {
                path: '/home/marketing/private_sms',
                name: 'private_sms',
                component: resolve => require(['../views/marketing/private_sms.vue'], resolve),
                meta: { title: "私信回复", requireAuth: true, navGruop: 'marketing' },
            },
            {
                path: '/home/marketing/follow',
                name: 'marketingFollow',
                component: resolve => require(['../views/marketing/follow.vue'], resolve),
                meta: { title: "关注回复", requireAuth: true, navGruop: 'marketing' },
            },
            {
                path: '/home/marketing/like',
                name: 'marketingLike',
                component: resolve => require(['../views/marketing/like.vue'], resolve),
                meta: { title: "点赞回复", requireAuth: true, navGruop: 'marketing' },
            }
        ],
        meta: {
            title: "营销中心", requireAuth: true, navGruop: 'marketing', children: true,
            img: require('@/assets/images/nav/yin.png'),
            img_select: require('@/assets/images/nav/ci_2.png'),
        }
    },
    {
        // 关键词排名
        path: '/home/expressions',
        name: 'expressions',
        component: resolve => require(['../views/expressions/index.vue'], resolve),
        children: [],
        meta: {
            title: "关键词管理", requireAuth: true, navGruop: 'expressions',
            img: require('@/assets/images/nav/ci.png'),
            img_select: require('@/assets/images/nav/ci_2.png'),
        }
    }, {
        // 个人中心
        path: '/home/user/index',
        name: 'user',
        meta: {
            title: "个人中心", requireAuth: true, navGruop: 'user', children: true,
            img: require('@/assets/images/nav/user.png'),
            img_select: require('@/assets/images/nav/user_2.png'),
        },
        component: resolve => require(['../views/user/index.vue'], resolve),
        children: [{
            path: '/home/user/index',
            name: 'user_info',
            component: resolve => require(['../views/user/info.vue'], resolve),
            meta: { title: "基本信息", requireAuth: true, navGruop: 'user' },
        }, {
            path: '/home/user/order',
            name: 'user_order',
            component: resolve => require(['../views/user/order.vue'], resolve),
            meta: { title: "充值记录", requireAuth: true, navGruop: 'user' },
        }, {
            path: '/home/user/consumption',
            name: 'user_consumption',
            component: resolve => require(['../views/user/consumption.vue'], resolve),
            meta: { title: "消费明细", requireAuth: true, navGruop: 'user' },
        },
        {
            path: '/home/user/password',
            name: 'user_password',
            component: resolve => require(['../views/user/password.vue'], resolve),
            meta: { title: "安全中心", requireAuth: true, navGruop: 'user' },
        }]
    },
    //     {
    //     // 子账号管理
    //     path: '/home/role/index',
    //     name: 'role',
    //     meta: {
    //         title: "子账号管理", requireAuth: true, navGruop: 'role', children: true,
    //         img: require('@/assets/images/nav/user.png'),
    //         img_select: require('@/assets/images/nav/user_2.png'),
    //     },
    //     component: resolve => require(['../views/role/index.vue'], resolve),
    //     children: [
    //         {
    //             path: '/home/role/list',
    //             name: 'role_list',
    //             meta: {
    //                 title: "子账号列表", requireAuth: true, navGruop: 'role', children: true,
    //                 img: require('@/assets/images/nav/user.png'),
    //                 img_select: require('@/assets/images/nav/user_2.png'),
    //             },
    //             component: resolve => require(['../views/role/list.vue'], resolve),
    //         }

    //     ]
    // }
]