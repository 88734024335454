const data = [{
    icon: 'iconfont icon-shouye',
    text: '首页',
    path: '/home/index',
    navGruop: 'index',
    img: require('@/static/images/index/syhui2x.png'),
    img_select: require('@/static/images/index/syelan2x.png')
}, {
    icon: 'iconfont icon-kehu',
    text: '工作台',
    path: '/home/work/index',
    navGruop: 'word',
    img: require('@/static/images/index/gzt2x (2).png'),
    img_select: require('@/static/images/index/gzt2x.png')
}, {
    icon: 'iconfont icon-kehu',
    text: '脚本库',
    path: '/home/script',
    navGruop: 'script',
    img: require('@/static/images/index/joabenku 2x.png'),
    img_select: require('@/static/images/index/joabenku2x.png')
}, {
    icon: 'iconfont icon-kehu',
    text: '矩阵管理',
    path: '/home/matrix',
    navGruop: 'matrix',
    img: require('@/static/images/index/juzheng2x.png'),
    img_select: require('@/static/images/index/juzheng2x (2).png')
}, {
    icon: 'iconfont icon-kehu',
    text: '引流工具',
    path: '/home/tool',
    navGruop: 'tool',
    img: require('@/static/images/index/yingliu2x.png'),
    img_select: require('@/static/images/index/yingliu2x (2).png')
}, {
    icon: 'iconfont icon-kehu',
    text: '关键词排名',
    path: '/home/ranking',
    navGruop: 'ranking',
    img: require('@/static/images/index/paiming2x (2).png'),
    img_select: require('@/static/images/index/paiming2x.png')
}, {
    icon: 'iconfont icon-kehu',
    text: '关键词',
    path: '/home/expressions',
    navGruop: 'expressions',
    img: require('@/static/images/index/guanjian2x (2).png'),
    img_select: require('@/static/images/index/guanjian2x.png')
}, {
    icon: 'iconfont icon-kehu',
    text: '个人中心',
    path: '/home/user/index',
    navGruop: 'user',
    img: require('@/static/images/index/geren 2x.png'),
    img_select: require('@/static/images/index/geren2x.png')
}]
const video = [{
    icon: 'iconfont icon-shouye',
    text: '素材调试',
    path: '/make/debugging',
    navGruop: 'makeDebugging',
    img: require('@/static/images/work/shuchaitaoshi2.png'),
    img_select: require('@/static/images/work/shuchaitaoshi.png')
}, {
    icon: 'iconfont icon-kehu',
    text: '智能混剪',
    path: '/make/editing',
    navGruop: 'makeEditing',
    img: require('@/static/images/work/zhinngehunjian.png'),
    img_select: require('@/static/images/work/zhinngehunjian (2).png')
}, {
    icon: 'iconfont icon-kehu',
    text: '组合优化',
    path: '/make/combination',
    navGruop: 'makeCombination',
    img: require('@/static/images/work/zhuheyouhua (2).png'),
    img_select: require('@/static/images/work/zhuheyouhua (3).png')
}]
module.exports = {
    data: data,
    video: video
}