<template>
    <el-container style="height: 100%; background-color: #fff">
        <el-header>
            <HEADER />
        </el-header>
        <el-container>
            <el-aside class="nav" width="auto">
                <LEFT_NAV :isCollapse="isCollapse" />
            </el-aside>
            <el-main style="padding: 20px; background-color: #f6f9ff">
                <transition name="transitionRouter">
                    <router-view></router-view>
                </transition>
            </el-main>
        </el-container>
    </el-container>
</template>
  
  <script>
//
import HEADER from "@/components/top";
import NAV_ITEM from "@/components/navItem";
import LEFT_NAV from "@/components/left_nav";
import { getUSer, logout } from "@/api/user";
export default {
    name: "Home",
    components: {
        HEADER,
        // NAV_ITEM,
        LEFT_NAV,
    },
    data() {
        return {
            isCollapse: true,
        };
    },
    methods: {
        goIndex() {
            this.$router.push("/home");
        },
        logout() {
            // logout().then(res)
            this.$confirm("确定要退出系统么?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    logout().then((res) => {
                        if (res.code == 200) {
                            sessionStorage.removeItem("userInfo");
                            sessionStorage.removeItem("token");
                            this.$store.commit("LAY_OUT");
                            this.$router.push({ path: "/" });
                        }
                    });
                })
                .catch(() => {
                    // this.$message.info("已取消退出");
                });
        },
    },
    computed: {},
    created() {},
};
</script>
  
<style lang="less">
.el-container {
    height: 0;
}
</style>
  <style lang="less" scoped>
.nav {
    max-width: 230px;
}
.top_bar {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}
.right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btns-box {
        display: flex;
    }
    .icon-btn {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        background-color: #f5f6f8;
        color: #345582;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
    }
    .user-info {
        display: flex;
        align-items: center;
    }
}
.user-logo {
    width: 34px;
    border-radius: 50%;
}
.down-box {
    display: flex;

    align-items: center;
    span {
        padding: 0 4px;
    }
    .user-info {
        padding: 0 10px;

        .user-id {
            font-size: 12px;
        }

        .user-name {
            // font-size: 13px;
        }
    }
}
</style>