import Vue from 'vue'
import Vuex from 'vuex'
import router from '../utils/router'
Vue.use(Vuex)
import createPersistedState from 'vuex-persistedstate'

const store = new Vuex.Store({
    state: {
        router: router.data,
        token: '' || localStorage.getItem('auto_token'),
        user: {}, //用户
        video: '',     //视频地址
        audio: '',   //音频地址
        music_cover: '',
        pic: '',      //图片地址
        ossVideoUrl: 'https://cncsvideo.oss-cn-shanghai.aliyuncs.com/',
        ossAudioUrl: 'https://cncsaudio.oss-cn-shanghai.aliyuncs.com/',
        ossImgUrl: 'https://cncspicture.oss-cn-shanghai.aliyuncs.com/',
        web: 'https://hotkeey.cn',          //网站地址
        select_fiss: {
            type: '', //类型，video，audio
            group: '', //组索引
            index: ''  //子级索引
        },
        uploads: [],
        upload_wait: [],
        fiss_id: '',
        fiss_data: {
            intputCount: "1000",
            id: '',
            script: {
                Title: "TEST", //工程标题
                Description: "TEST", //工程描述
                Action: "CreateEditingProject",
                scriptTemplateId: "", //引用脚本ID
                scriptClipsParam: {
                    title: "", //脚本标题
                    scriptClass: "GenericScript",
                    // canvas: '',//视频宽高
                    // rate: '', //视频分辨率
                    canvas: '{"width":9,"height":16}',
                    rate: '{"x":1080,"y":1920}',
                    filter: "",  //筛选合成时长
                    volume: 50,  //BMG音频音量
                    unify_volume: 0,    //统一音量
                    variableSpeed: "",   //视频/音频同步变速
                    backGroundAudio: {
                        backGroundList: [],
                    },
                    group: [],
                },
            },
        },
        fiss_group: '[]',
        bgm_set: {
            volume: 100, //BGM音量
            paly_add: 1, //播放位置 1延迟播放s, 2选定组播放
            identify: 0, //字幕识别 0不识别，1识别

        },
        bgm: [],
        timeLine: '',

    },
    getters: {
        GET_FISS_COUNT(state) {
            return state.fiss_data.intputCount;
        },
        GET_BGM_SET(state) {
            return state.bgm_set
        },
        VIDEO_LEN(state) {
            let len = 0
            let fiss_group = JSON.parse(state.fiss_group)
            console.log(fiss_group);
            // fiss_group.forEach(item => {
            //     len += item.video.length
            // })
            // return len;
        },
        GET_GROUP_SET(state) {
            let index = state.select_fiss.group
            let fiss_group = JSON.parse(state.fiss_group)
            let group_set = {};
            console.log(index);
            if (index !== '' && index !== -1) {
                group_set = fiss_group[index].setting
                // group_set = Vue.get(state.fiss_group[index].setting)
            }
            console.log(group_set);
            return group_set;

        },
        GET_GROUP_AUDIO(state) {
            let index = state.select_fiss.group
            console.log(index);
            let group_audio = [];
            if (index !== '') {
                let fiss_group = JSON.parse(state.fiss_group)
                group_audio = fiss_group[index].audio
            }
            return group_audio;
        },
        GET_GROUP(state) {
            return JSON.parse(state.fiss_group)
        },
        GET_TRANSITIONG(state) {
            let index = state.select_fiss.group
            let fiss_group = JSON.parse(state.fiss_group)
            let transition = ''
            if (index === '' || index === -1) return transition;
            return fiss_group[index].setting.transition
        },
        GET_BGM(state) {
            return state.bgm
        },
        GET_TIMELINE(state) {
            let timeline = JSON.parse(state.timeLine)
            let edits = {
                txts: [],
                images: [],
                filters: []
            }
            timeline.VideoTracks.forEach(item => {
                switch (item.Type) {
                    case 'Subtitle':
                        edits.txts.push(item)
                        break;
                    case 'Effect':

                        edits.filters.push(item)
                        break
                    case 'Image':
                        edits.images.push(item)

                        break
                    default:
                        break;
                }
            })
            let line = {
                edits,
                timeLine: timeline
            }
            // console.log('获取时间线');
            // console.log();
            return line;
        }
    },
    mutations: {
        SET_FISS_COUNT(state, num) {
            state.fiss_data.intputCount = num
        },
        SET_FISS_ID(state, id) {
            state.fiss_id = id
        },
        SET_BGM_PLAY_ADD(state, val) {
            state.bgm_set.paly_add = val;
        },
        SET_BGM_IDENTIFY(state, val) {
            state.bgm_set.identify = val;

        },
        SET_BGM_VOLUME(state, val) {
            state.bgm_set.volume = val;
            state.bgm.forEach(item => {
                item.volume = val
            })
        },
        CLEAR_FISS(state) {
            state.fiss_group = '[]';
            state.bgm = []
            state.fiss_id = ''
            state.select_fiss.group = ''
            state.select_fiss.type = ''
            state.select_fiss.index = ''
        },
        RESET_SCRIPT(state, info) {
            // debugger
            console.log(info);
            state.select_fiss.group = ''
            state.select_fiss.type = ''
            state.select_fiss.index = ''
            let group = info.gruop;
            state.fiss_data.script.scriptClipsParam.canvas = JSON.stringify(info.canvas)
            state.fiss_data.script.scriptClipsParam.rate
                = JSON.stringify(info.rate)

            group.forEach(item => {
                let setting = {
                    mix: 0, //混剪模式0，单镜头，1，单次混剪，2，智能混剪
                    transition: "", //转场效果
                    duration: 0.5, //转场时间
                    cropping: 0,  //1，去头，2，去尾，3去头去尾
                    follow: 2,  //跟随音频 1，跟随，2不跟随 
                    group_status: 1, //1，激活,0不激活
                    volume: 100, //原生音量大小
                    group_volume: 100, //组内音频音量大小}
                }
                item.setting = setting
                // state.fiss_group.push(item)
            })
            // Vue.set(state, 'fiss_group', group)
            state.fiss_group = JSON.stringify(group)
            // console.log(group);

        },
        RESET_FISS(state, info) {
            // console.log(info.scriptClipsParam.group);
            let group = info.group
            group.map(item => {
                item.audio = item.mouthAudio.mouthAudioList
                item.video = item.videocreationFile

            })
            let bgm = info.backGroundAudio;
            state.bgm = bgm.backGroundList
            state.bgm_set.identify = bgm.identify
            state.bgm_set.paly_add = bgm.paly_add
            state.bgm_set.volume = bgm.volume

            // console.log(group);
            state.fiss_group = JSON.stringify(group)
        },
        SET_VOLUME(state, val) {
            console.log(val);
            let index = state.select_fiss.group
            console.log(index);

            if (index === '') return
            let fiss_group = JSON.parse(state.fiss_group)
            if (val.isSyn) {
                // 同步设置所有原生
                console.log('同步设置所有原生');
                fiss_group.forEach(item => {
                    item.setting.volume = val.val
                    console.log(item);
                })
            } else {
                // 单独设置原生
                fiss_group[index].setting.volume = val.val
                console.log(`${index}组内音量${val.val}`);
            }
            state.fiss_group = JSON.stringify(fiss_group)
        },
        SET_GROUP_VOLUME(state, val) {
            console.log(val);
            let index = state.select_fiss.group
            let fiss_group = JSON.parse(state.fiss_group)
            if (index !== '') {
                fiss_group[index].setting.group_volume = val
            }
            state.fiss_group = JSON.stringify(fiss_group)
        },
        SET_CROPPING(state, val) {
            let fiss_group = JSON.parse(state.fiss_group);
            fiss_group[state.select_fiss.group].setting.cropping = val
            state.fiss_group = JSON.stringify(fiss_group)
        },
        SET_FOLLOW(state, val) {
            let fiss_group = JSON.parse(state.fiss_group);
            fiss_group[state.select_fiss.group].setting.follow = val
            state.fiss_group = JSON.stringify(fiss_group)
        },
        SET_GROUP_STATUS(state, val) {
            let fiss_group = JSON.parse(state.fiss_group);
            fiss_group[state.select_fiss.group].setting.group_status = val
            state.fiss_group = JSON.stringify(fiss_group)
        },
        SET_USER_INFO(state, info) {
            // console.log(info);
            state.user = info
        },
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SEt_USER_INFO(state, userinfo) {
            state.user = userinfo
        },
        SET_FISS(state, source) {
            // console.log(source);
            let select_fiss = state.select_fiss
            let fiss_data = JSON.parse(state.fiss_group)
            // console.log(select_fiss);
            switch (select_fiss.type) {
                case 'video':
                    console.log('视频导入');
                    if (select_fiss.group >= 0) {

                        source.map(item => {
                            fiss_data[select_fiss.group].video.push(item)
                            state.fiss_group = JSON.stringify(fiss_data)
                        })
                    } else {
                        throw '没有选择分组'
                    }
                    break;
                case 'audio':
                    console.log('音频导入');
                    if (state.select_fiss.group == -1) {
                        console.log('bgm');
                        source.map(item => {
                            state.bgm.push(item)
                        })
                    } else {
                        source.map(item => {
                            fiss_data[select_fiss.group].audio.push(item)
                        })
                        state.fiss_group = JSON.stringify(fiss_data)
                        console.log('口播');
                    }
                    break;

                default:
                    console.log(111);
                    break;
            }
        },
        SET_FISS_SELECT(state, selected) {
            console.log(selected);
            // console.log(selected);
            state.select_fiss = Object.assign(state.select_fiss, selected)
        },
        ADD_GROUP(state, data) {
            let fiss_group = JSON.parse(state.fiss_group)
            let group = {
                audio: [],
                video: [],
                title: `新建分组_${state.fiss_group.length}`,
                setting: {
                    mix: 0, //混剪模式0，单镜头，1，单次混剪，2，智能混剪
                    transition: "", //转场效果
                    duration: 0.5, //转场时间
                    cropping: 0,  //1，去头，2，去尾，3去头去尾
                    follow: 2,  //跟随音频 1，跟随，2不跟随
                    group_status: 1, //1，激活,0不激活
                    volume: 100, //原生音量大小}
                    group_volume: 100, //组内音频音量大小}
                }
            }
            fiss_group.push(group);
            state.fiss_group = JSON.stringify(fiss_group)
        },
        SET_GROUP_NAME(state, group) {

            let fiss_group = JSON.parse(state.fiss_group)
            fiss_group[group.group].title = group.title
            state.fiss_group = JSON.stringify(fiss_group)
        },
        DEL_GROUP(state, index) {
            let fiss_group = JSON.parse(state.fiss_group)
            fiss_group.splice(index, 1)
            state.fiss_group = JSON.stringify(fiss_group)
        },
        SET_GROUP_MIX(state, mode) {
            let fiss_group = JSON.parse(state.fiss_group)
            // console.log(value);
            if (mode.all_select) {
                //所有同步模式
                fiss_group.forEach(item => {
                    item.setting.mix = mode.val
                });
            } else {
                // 单个模式
                let index = state.select_fiss.group;
                fiss_group[index].setting.mix = mode.val;
            }
            state.fiss_group = JSON.stringify(fiss_group)

        },
        SET_GROUP_TRANSITION(state, mode) {
            console.log(mode);
            let fiss_group = JSON.parse(state.fiss_group);
            // console.log(fiss_group);
            if (state.select_fiss.group === '' || state.select_fiss.group === -1) return;
            if (mode.all_select) {
                //所有同步模式
                console.log('转场同步');
                fiss_group.forEach(item => {
                    item.setting.transition = mode.transition
                    item.setting.duration = mode.duration
                });
            } else {
                // 单个模式
                console.log('转场没有同步');
                let index = state.select_fiss.group;
                // Vue.set(state.fiss_group[index].setting, 'transition', mode.transition)
                fiss_group[index].setting.transition = mode.transition;
                fiss_group[index].setting.duration = mode.duration;
            }
            state.fiss_group = JSON.stringify(fiss_group)

        },
        DEL_VIDEO(state, video) {
            console.log(video);
            let fiss_group = JSON.parse(state.fiss_group)
            console.log(fiss_group[video.group].video);
            fiss_group[video.group].video.splice(video.index, 1)
            // console.log(fiss_group.group[video.group]);
            state.fiss_group = JSON.stringify(fiss_group)
        },
        SET_GROUP_DURATION(state, value) {
            let index = state.select_fiss.group;
            state.fiss_group[index].setting.duration = value;
        },
        DEL_AUDIO(state, data) {
            let type = data.type;  //1BMG,2组内音频
            let index = data.index;
            let fiss_group = JSON.parse(state.fiss_group)
            if (type == 1) {
                state.bgm.splice(data.index, 1)
            } else {
                fiss_group[state.select_fiss.group].audio.splice(index, 1)
            }
            state.fiss_group = JSON.stringify(fiss_group)
        },
        SET_TIMELINE(state, data) {
            console.log('更改时间线');
            state.timeLine = data;
        },
        UPDATA_TIMELINE_FILTER(state, data) {
            console.log('设置滤镜');
        },
        ADD_IMG(state, data) {
            let timeLine = JSON.parse(state.timeLine);
            let trackClip = {
                Id: 1,
                Type: "Image",
                Count: 1,
                VideoTrackClips: [
                    {
                        Type: "Image",
                        Id: '',
                        TrackId: 1,
                        // MediaId: "66864e82a7079a2d9625b9f618ecdc18",
                        Src: "",
                        TimelineIn: 0,
                        TimelineOut: 5,
                        Duration: 15,
                        X: 0.5,
                        Y: 0.5,
                        Width: 0.185,
                        Height: 0.104,
                        BusinessType: "Sticker",
                    },
                ],
            }
            trackClip.Id = Math.ceil(Math.random() * 100000000);
            trackClip.VideoTrackClips[0].Id = Math.ceil(Math.random() * 100000000);
            trackClip.VideoTrackClips[0].Src = data.url;
            trackClip.VideoTrackClips[0].TimelineIn = data.timeIn;
            trackClip.VideoTrackClips[0].TimelineOut = data.timeIn + 5;
            timeLine.VideoTracks.push(trackClip);
            state.timeLine = JSON.stringify(timeLine)
            // console.log(trackClip);
        },
        ADD_FILTER(state, val) {
            let timeline = JSON.parse(state.timeLine);
            console.log(timeline);
            let index = timeline.VideoTracks.findIndex(item => item.Type === 'Effect');
            console.log(index);
            if (index != -1) {
                // 已存在滤镜，修改
                console.log('存在滤镜');
                timeline.VideoTracks[index].VideoTrackClips[0].SubType = val;
                timeline.VideoTracks[index].VideoTrackClips[0].Name = `filters.${val}`
                timeline.VideoTracks[index].VideoTrackClips[0].ExtParams = `effect=${val}`
                state.timeLine = JSON.stringify(timeline)
            } else {
                // 不存在滤镜，添加
                console.log('不存在滤镜');
                let trackClip = {
                    Id: Math.ceil(Math.random() * 10000000),
                    Type: "Effect",
                    Visible: true,
                    Count: 1,
                    VideoTrackClips: [
                        {
                            Type: "Filter",
                            Id: Math.ceil(Math.random() * 10000000),
                            TrackId: 3,
                            Index: 138,
                            SubType: "pfc", //滤镜类型
                            Name: "filters.pfc",
                            CoverUrl:
                                "https://ice-pub-media.myalicdn.com/websdk/v2/filters/pfc.jpg",
                            Properties: {},
                            Category: "film",
                            EffectType: "filter",
                            EffectConfig: {
                                Image: "https://ice-pub-media.myalicdn.com/websdk/lut/32/PFC.png",
                            },
                            TimelineIn: 0,
                            TimelineOut: 100,
                            Duration: 100,
                            X: 0,
                            Y: 0,
                            Width: 0,
                            Height: 0,
                            CanScale: false,
                            ExtParams: "effect=pfc",
                        },
                    ],
                }
                trackClip.VideoTrackClips[0].SubType = val
                trackClip.VideoTrackClips[0].Name = `filters.${val}`
                trackClip.VideoTrackClips[0].ExtParams = `effect=${val}`
                timeline.VideoTracks.push(trackClip);
                state.timeLine = JSON.stringify(timeline)
                console.log(state.timeLine);
            }
        },
        upGroupVideo(state, val) {
            console.log(JSON.parse(state.timeLine));
            console.log(val);
        }
    },
    //把所有数据缓存到本地
    // plugins: [createPersistedState({ storage: window.localStorage, })],
    plugins: [createPersistedState({ storage: window.sessionStorage, })],
})

export default store;