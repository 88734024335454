export default [{
    path: '/make/debugging',
    name: 'make',
    // redirect: '/clip',
    // component: resolve => require(['../views/make/debugging.vue'], resolve),
    component: resolve => require(['../views/make/clip_list.vue'], resolve),
    meta: {
        requireAuth: true, hidden: false, navGruop: 'makeDebugging', icon: 'icon-a-sucaitiaoshi', black: "black",
    },
}, {
    path: '/make/editing',
    name: 'makeEditing',
    component: resolve => require(['../views/make/editing.vue'], resolve),
    meta: {
        requireAuth: true, hidden: false, navGruop: 'makeEditing', icon: 'icon-a-zhinenghunjian'
    },
}, {
    path: '/make/combination',
    name: 'makeCombination',
    component: resolve => require(['../views/make/combination.vue'], resolve),
    meta: {
        requireAuth: true, hidden: false, navGruop: 'makeCombination', icon: 'icon-a-zuheyouhua'
    },
}, {
    path: '/make/edit/:pojectId',
    name: 'edit',
    component: resolve => require(['../views/make/clip.vue'], resolve),
    meta: {
        requireAuth: true, hidden: true, navGruop: 'makeCombination', black: "black",
    },
}, {
    path: '/make/fiss_list/:pojectId',
    name: 'fiss_list',
    component: resolve => require(['../views/make/fiss_list'], resolve),
    meta: {
        requireAuth: true, hidden: true, navGruop: 'makeCombination',
    },
}]