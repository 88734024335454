<template>
    <!-- <div class="progress" :style="style"> -->
    <div class="progress" :style="style">
        <div
            class="progress-bar progress-bar-striped"
            :class="{
                active: isProgress,
                error: status == 'Error',
            }"
            style="width: 100%"
        ></div>
    </div>
</template>

<script>
export default {
    name: "Progress",
    props: {
        isProgress: {
            default: true,
        },
        status: {
            default: "Error",
        },
    },
    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
.progress {
    height: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress-bar.active {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    -o-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}
.progress-bar-striped,
.progress-striped .progress-bar {
    background-image: -webkit-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-image: -o-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    -webkit-background-size: 20px 20px;
    background-size: 20px 20px;
}
@keyframes progress-bar-stripes {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 40px 0;
    }
}
.progress-bar {
    float: left;
    width: 0%;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    // background-color: #337ab7;
    background-color: #409eff;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}
</style>