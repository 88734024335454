import request from '@/utils/request'

// 通过ID查询工程
export function getProj(proId) {
    return request({
        url: '/api/app/aliYun/apiPost',
        data: {
            Id: proId,
            Action: "GetEditingProject"
        },
        method: 'POST'
    })
}
// 查询工程列表
export function getProje(params) {
    return request({
        url: '/api/app/editingProject',
        params,
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    })
}
// 创建工程
export function createProje(data) {
    // console.log(data);
    return request({
        url: '/api/app/aliYun/apiPost',
        method: "POST",
        data
    })
}
// 删除工程

export function delProje(uid) {
    // console.log(data);
    return request({
        url: '/api/app/editingProject/batchDelete',
        method: "POST",
        data: {
            ids: uid
        }
    })
}
// 生成列表预览 /api/app/Videocreationfile/makePreview
export function editFission(data) {
    // console.log(data);
    return request({
        url: '/api/app/editingProject',
        method: "POST",
        data
    })
}
// 生成列表预览 /api/app/Videocreationfile/makePreview
export function getFission(data) {
    return request({
        // url: '/api/app/Videocreationfile/makePreview',
        // url: '/api/app/Videocreationfile/GenerativeFission',
        url: '/api/app/videoFission/GenerativeFission',
        method: "POST",
        data
    })
}
export function setPreviewState(data) {
    return request({
        url: `/api/app/VideoPreviewFile/update/${data.id}`,
        method: "POST",
        data: {
            videoStatus: data.state
        }
    })
}
// 保存裂变配置 /api/app/Videocreationfile/makePreview
export function saveFissSet(data) {
    return request({
        // url: '/api/app/Videocreationfile/makePreview',
        url: '/api/app/Videocreationfile/SaveConfiguration',
        method: "POST",
        data
    })
}
// 合成裂变视频
export function gitFission(data) {
    return request({
        url: '/api/app/VideoGenerationFile/VideoMergeVideos',
        method: "POST",
        data
    })
}
// 文本转语音
export function gitAudio(data) {
    // console.log(data);
    return request({
        url: '/api/app/voiceGenerate/voiceGenerateByObject',
        method: "get",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: {
            ...data
        },
    })
}
export function getTimeLine(data) {
    return request({
        url: '/api/app/VideoGenerationFile/GenerateTimeLine',
        method: "post",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}

export function getPreview(data) {
    return request({
        url: '/api/app/aliYun/apiPostTest',
        method: "post",
        data
    })
}
export function getPreviewList(params) {
    return request({
        url: '/api/app/VideoPreviewFile/selectByList',
        method: "get",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params
    })
}


export function generate(data) {
    return request({
        url: '/api/app/VideoGenerationFile/generateVideo',
        method: "post",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}


export function taskList(data) {
    return request({
        url: '/api/app/accounttask/Page',
        method: "get",
        data
    })
}
export function generateCaptions(data) {
    return request({
        url: '/api/app/VideoGenerationFile/generateCaptions',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
export function gitTimeLine(data) {
    return request({
        url: '/api/app/VideoGenerationFile/editTimeLine',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
export function getStickerImg(data) {
    return request({
        url: '/api/app/aliYun/apiPostTest',
        method: 'post',
        data
    })
}
// 获取转场资源数据
export function getTransitionImg(params) {
    return request({
        url: '/api/app/PcwebPictureFile',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params
    })
}
// 更新工程数据
export function updata_project(data) {
    return request({
        url: '/api/app/editingProject/update',
        method: 'post',
        data
    })
}
// 获取花字
export function getHuazi() {
    return request({
        url: '/api/app/PcwebPictureFile',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: {
            directoryId: 7,
            size: 100
        }
    })
}
export function getProjectInfo(pojectId) {
    return request({
        url: `/api/app/editingProject/info/${pojectId}`,
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}
