<style lang="less">
.el-menu--vertical {
    .el-submenu,
    .el-menu-item {
        height: 46px !important;
        line-height: 46px !important;
        margin-top: 0;
    }
    .el-menu--popup {
        min-width: 130px;
    }
}
.left_nav {
    height: 100%;
    position: relative;
    overflow: hidden auto;
    .close_btn {
        width: 4px;
        height: 20px;
        background: #000;
        z-index: 99;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .el-menu {
        height: 100%;
        width: 230px;
    }
    .el-menu--collapse {
        width: 80px;
        .el-submenu,
        .el-menu-item {
            margin-top: 20px;
        }
        .active {
            .nav-item {
                background: #279aff;
                .nav_icon {
                    background-position-y: 16px;
                }
            }
        }
        .nav-item {
            display: flex;
            width: 40px;
            height: 40px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            background-color: #f5f6f8;
            border-radius: 8px;
        }
        .el-submenu__title,
        .el-tooltip {
            padding: 0 !important;
            padding-top: 8px !important;
        }
    }
    .el-submenu__title *,
    .el-menu-item * {
        vertical-align: initial;
        transform: translateY(1px);
    }
    .active {
        .nav_icon {
            background-position-y: 0;
        }
        .nav_txt,
        .el-submenu__icon-arrow {
            color: #279aff;
        }
    }
}
.nav-item {
    display: inline-block;
    // vertical-align: middle;
    margin-right: 8px;
    line-height: 0;
    .nav_icon {
        display: inline-block;

        width: 16px !important;
        height: 16px !important;
        background-size: 16px;
        background-position-y: -16px;
        visibility: visible !important;
    }
}
.el-menu--collapse > .nav-item {
    margin-right: 10px;
}
</style>
<template>
    <nav class="left_nav">
        <el-menu
            default-active="1-1"
            class="el-menu-vertical-demo"
            router
            :collapse="isCollapse"
        >
            <template v-for="(item, index) in route">
                <el-menu-item
                    :index="item.path"
                    :key="index"
                    v-if="item.children.length == 0"
                    :class="
                        $route.meta.navGruop == item.meta.navGruop
                            ? 'active'
                            : ''
                    "
                >
                    <div class="nav-item">
                        <span
                            class="nav_icon"
                            :style="{
                                backgroundImage: `url(${item.meta.img})`,
                            }"
                        ></span>
                    </div>
                    <span slot="title" class="nav_txt">{{
                        item.meta.title
                    }}</span>
                </el-menu-item>
                <el-submenu
                    :index="item.path"
                    :key="index"
                    v-else
                    :class="
                        $route.meta.navGruop == item.meta.navGruop
                            ? 'active'
                            : ''
                    "
                >
                    <template slot="title">
                        <div class="nav-item" slot="reference">
                            <span
                                class="nav_icon"
                                :style="{
                                    backgroundImage: `url(${item.meta.img})`,
                                }"
                            ></span>
                        </div>
                        <span slot="title" class="nav_txt">{{
                            item.meta.title
                        }}</span>
                    </template>
                    <el-menu-item
                        :index="list.path"
                        v-for="(list, i) in item.children"
                        :key="i"
                        v-show="!list.meta.hidden"
                        >{{ list.meta.title
                        }}{{ list.meta.hidden }}</el-menu-item
                    >
                </el-submenu>
            </template>
        </el-menu>
    </nav>
</template>

<script>
import Bus from "@/utils/eventBus.js";
import routes from "@/router/routes";
export default {
    name: "",
    data() {
        return {
            route: [],
            isCollapse: false,
        };
    },
    created() {
        this.route = routes;
        Bus.$on("navType", (val) => {
            //  转存数据
            this.isCollapse = val;
        });
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="less">
.nav-poper {
    padding: 0 !important;
    border-radius: 10px !important;
    background-color: #f5f6f8 !important;
    overflow: hidden;
    .popper__arrow {
        display: none !important;
    }
    .childer-nav {
        line-height: 44px;
        .nav-list {
            padding: 0 12px;
            &:hover {
                background-color: #349ffd;
                a {
                    color: #fff;
                    display: block;
                }
            }
            a {
                color: #334681;
            }
        }
    }
}
</style>
<style lang="less" scoped>
.leftMenu {
    background-color: #fff;
    .nav-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .nav-item {
            width: 44px;
            height: 44px;
            margin-top: 50px;
            background-size: cover;
            cursor: pointer;
            a {
                display: block;
            }
            &:hover,
            &.active {
                background-position-y: 100%;
            }
            img {
                width: 100%;
            }
        }
    }
}
// .leftMenu {
//     height: 100%;
//     z-index: 9;
//     transition: 0.2s;

//     .leftMenu_ul {
//         width: 100%;

//         .leftMenu_box {
//             width: 100%;
//             height: 60px;
//             display: flex;
//             align-items: center;
//             cursor: pointer;

//             img {
//                 width: 20px;
//                 height: 20px;
//                 margin-left: 30px;
//                 margin-right: 15px;
//             }

//             .active {
//                 color: #349ffd;
//             }

//             i {
//                 margin-left: 20px;
//                 margin-right: 10px;
//                 font-size: 18px;
//             }

//             .icon-xiangyou {
//                 color: #fff;
//                 font-size: 13px;
//                 position: relative;
//                 left: 10px;
//             }

//             .icon-xiangyou_select {
//                 transition: 0.3s;
//                 transform: rotate(90deg);
//             }
//         }
//     }
// }
</style>